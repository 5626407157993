import axios from 'axios'

class EmployeeApi {
  async get({ page, limit, search, start_date, end_date }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    const response = await axios.get(`counting/employees?page=${page}&limit=${limit}&search=${search}&start_date=${start_date}&end_date=${end_date}`)
    return response
  }

  async getAll() {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    const response = await axios.get('counting/employees/getAll')
    return response
  }

  async getEmployeeProfile(id) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    const response = await axios.get(`counting/employees/employee_id/${id}`)
    return response
  }

  async getCurrentYearAndCurrentMonth() {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    const response = await axios.get('counting/otherSalary/statistics/currentYearAndCurrentMonth')
    return response
  }

  async add({
    employee_name,
    employee_address,
    employee_mobile,
    employee_salary,
    employee_section,
    employee_start_date,
    employee_note,
  }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    const response = await axios.post('counting/employees', {
      employee_name,
      employee_address,
      employee_mobile,
      employee_salary,
      employee_section,
      employee_start_date,
      employee_note,
    })
    return response
  }

  async edit({
    employee_id,
    employee_name,
    employee_address,
    employee_mobile,
    employee_salary,
    employee_section,
    employee_start_date,
    employee_note,
  }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    const response = await axios.put(`counting/employees`, {
      employee_id,
      employee_name,
      employee_address,
      employee_mobile,
      employee_salary,
      employee_section,
      employee_start_date,
      employee_note,
    })
    return response
  }

  async remove(id) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    const response = await axios.delete(`counting/employees/employee_id/${id}`)
    return response
  }
}

export default new EmployeeApi()
