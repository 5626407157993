<template>
  <div>
    <v-card class="primary-shade--light pa-3">
      <h1 class="text-center subtitle-4 black--text">الموظفين</h1>
      <v-row class="mt-5">
        <v-col md="4" cols="12">
          <div class="d-md-flex">
            <v-btn
              tile
              color="primary"
              class="ml-2 mb-4 mb-md-0 mb-sm-0"
              :block="isScreenXs"
              @click="addDialog.open = true"
            >
              اضافة موظف<v-icon right> fa-plus </v-icon>
            </v-btn>
          </div>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
            <v-row>
        <v-col md="2" cols="12">
          <v-menu v-model="menuStartDate" :close-on-content-click="false" :nudge-right="40"
            transition="scale-transition" offset-y min-width="290px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="start_date" dense label="من" outlined clearable readonly
                v-bind="attrs" v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="start_date" @input="menuStartDate = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col md="2" cols="12">
          <v-menu v-model="menuEndDate" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
            offset-y min-width="290px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="end_date" dense label="الى" outlined clearable readonly
                v-bind="attrs" v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="end_date" @input="menuEndDate = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-spacer></v-spacer>
        <v-col md="3" cols="12">
          <div class="d-flex flex-row">
            <v-text-field v-model="table.search" label="البحث" outlined dense @keyup.enter="search()"></v-text-field>
            <button class="search-btn" @click="search()">
              <v-icon style="font-size: 18px; color: white"> fa-search </v-icon>
            </button>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="table.headers"
            loading-text="جاري التحميل ... الرجاء الانتظار"
            :items="table.Data"
            :options.sync="tableOptions"
            :server-items-length="table.totalData"
            :loading="table.loading"
            class="elevation-1"
            :footer-props="{
              itemsPerPageOptions: [10, 50, 100],
            }"
          >
            <template slot="item._id" slot-scope="props">
              {{ table.totalData - props.index - (tableOptions.page - 1) * tableOptions.itemsPerPage }}
            </template>
            <template slot="item.employee_name" slot-scope="props">
              <router-link :to="`employeeProfile/${props.item._id}`">
                {{ props.item.employee_name }}  
              </router-link>
            </template>
            <template slot="item.employee_salary" slot-scope="props">
              <v-chip color="primary" dark>{{ numberWithComma(props.item.employee_salary) }}</v-chip>
            </template>
            <template slot="item.salary_other.number_of_salary" slot-scope="props">
              <v-chip color="primary" dark>{{ numberWithComma(props.item.salary_other.number_of_salary) }}</v-chip>
            </template>
            <template slot="item.salary_other.total_amount" slot-scope="props">
              <v-chip color="primary" dark>{{ numberWithComma(props.item.salary_other.total_amount) }}</v-chip>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="primary" class="ml-2" v-bind="attrs" size="20" v-on="on" @click="editItem(item)">
                    fa-edit
                  </v-icon>
                </template>
                <span>تعديل</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="#FF5252" v-bind="attrs" size="20" v-on="on" @click="deleteItem(item)">
                    fa-trash
                  </v-icon>
                </template>
                <span>حذف</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <!-- add dialog -->
    <v-dialog v-model="addDialog.open" max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline">اضافة</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="addDialog.isFormValidAdd">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="addData.employee_name"
                    label="الاسم"
                    :rules="rules.required"
                    outlined
                    required
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="addData.employee_mobile" label="رقم الهاتف" outlined required dense>
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="addData.employee_section"
                    :rules="rules.required"
                    :loading="sections.loading"
                    :items="sections.data"
                    item-text="name"
                    item-value="_id"
                    clearable
                    outlined
                    dense
                    label="اختيار قسم"
                    @click:clear="addData.employee_section = null"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="6">
                  <v-currency-field
                    v-model="addData.employee_salary"
                    :error-messages="errors.rate"
                    :rules="rules.required"
                    dense
                    label="الراتب"
                    outlined
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="addData.employee_address" label="العنوان" outlined required dense>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-menu
                    v-model="menuDate"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="addData.employee_start_date"
                        dense
                        label="تاريخ بداية العمل"
                        outlined
                        clearable
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="addData.employee_start_date" @input="menuDate = false"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    outlined
                    rows="3"
                    row-height="15"
                    v-model="addData.employee_note"
                    label="الملاحظة"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="ml-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="addDialog.open = false"> الغاء </v-btn>
          <v-btn color="primary" :loading="addDialog.loading" :disabled="!addDialog.isFormValidAdd" @click="add">
            اضافة
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- add Class dialog -->
    <!-- edit Class dialog -->
    <v-dialog v-model="dialogEdit.open" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">تعديل</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="dialogEdit.isFormValid">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="editedItem.employee_name"
                    label="الاسم"
                    :rules="rules.required"
                    outlined
                    required
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="editedItem.employee_mobile" label="رقم الهاتف" outlined required dense>
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="editedItem.employee_section"
                    :rules="rules.required"
                    :loading="sections.loading"
                    :items="sections.data"
                    item-text="name"
                    item-value="_id"
                    clearable
                    outlined
                    dense
                    label="اختيار قسم"
                    @click:clear="editedItem.employee_section = null"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="6">
                  <v-currency-field
                    v-model="editedItem.employee_salary"
                    :error-messages="errors.rate"
                    :rules="rules.required"
                    dense
                    label="الراتب"
                    outlined
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="editedItem.employee_address" label="العنوان" outlined required dense>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-menu
                    v-model="menuDateEdit"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="editedItem.employee_start_date"
                        dense
                        label="تاريخ بداية العمل"
                        outlined
                        clearable
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="editedItem.employee_start_date"
                      @input="menuDateEdit = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    outlined
                    rows="3"
                    row-height="15"
                    v-model="editedItem.employee_note"
                    label="الملاحظة"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="ml-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogEdit.open = false"> الغاء </v-btn>
          <v-btn
            color="primary"
            :loading="dialogEdit.loading"
            :disabled="!dialogEdit.isFormValid"
            @click="editItemConform"
          >
            تعديل
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- delete dialog -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="headline justify-center"> هل انت متأكد من حذف هذا العنصر ؟ </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDelete = false"> الغاء </v-btn>
          <v-btn color="primary white--text" :loading="deleteItemLoading" @click="deleteItemConfirm"> حذف </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End delete dailog -->
    <!--- Dailog for show info to user-->
    <v-dialog v-model="dialogData.open" max-width="500px">
      <v-toolbar :color="dialogData.color" dense></v-toolbar>
      <v-card>
        <v-card-title class="headline justify-center"> {{ dialogData.bodyText }} </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1 justify-start" text @click="dialogData.open = false"> تم </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import employeeApi from '@/api/employee'
import numberWithComma from '@/constant/number'
import { mdiCash, mdiCashMultiple } from '@mdi/js'
import sectionApi from '@/api/section_other'

export default {
  data() {
    return {
      rate: 0,
      menuStartDate: false,
      menuEndDate: false,

      errors: {},

      sections: {
        data: [],
        loading: false,
      },

      menuDate: false,

      menuDateEdit: false,

      icons: {
        mdiCashMultiple,
        mdiCash,
      },

      rules: {
        required: [value => !!value || 'الحقل مطلوب'],
      },

      addData: {
        employee_name: null,
        employee_mobile: null,
        employee_address: null,
        employee_salary: 0,
        employee_section: null,
        employee_start_date: null,
        employee_note: null,
      },

      remainingPrice: null,
      remainingPriceEdit: null,

      allPrice: null,
      allPriceEdit: null,

      deleteItemLoading: false,

      content_url: null,

      dialogDelete: false,

      dialogData: {
        open: false,
        color: 'primary',
        bodyText: 'test',
      },

      editedItem: {},

      dialogEdit: {
        open: false,
        isFormValid: false,
        loading: false,
      },

      xlsxData: {
        list: null,
        listLoading: true,
        downloadLoading: false,
        filename: 'الرصيد المخزني',
        autoWidth: true,
        bookType: 'xlsx',
      },

      allData: [],

      isScreenXs: false,

      deletedItem: {},

      tableOptions: {},

      addDialog: {
        open: false,
        isFormValidAdd: false,
        saveLoading: false,
        loading: false,
      },

      suppliersLoading: false,

      suppliersData: [],

      materialLoading: false,

      materialsData: [],

      table: {
        search: null,
        totalData: 0,
        Data: [],
        loading: true,

        headers: [
          {
            text: '#',
            align: 'start',
            sortable: false,
            value: '_id',
            width: 1,
          },
          { text: 'الاسم', sortable: false, value: 'employee_name' },
          { text: 'الهاتف', sortable: false, value: 'employee_mobile' },
          { text: 'العنوان', sortable: false, value: 'employee_address' },
          { text: 'القسم', sortable: false, value: 'section_name' },
          { text: 'تاريخ بداية العمل', sortable: false, value: 'employee_start_date' },
          { text: 'الراتب', sortable: false, value: 'employee_salary' },
          { text: 'عدد الفواتير', sortable: false, value: 'salary_other.number_of_salary' },
          { text: 'المبلغ الإجمالي', sortable: false, value: 'salary_other.total_amount' },
          { text: 'الملاحظات', sortable: false, value: 'employee_note' },
          { text: 'العمليات', value: 'actions', sortable: false },
        ],
      },
      start_date: null,
      end_date: null,
    }
  },
  watch: {
    '$route.query.search': {
      handler(search) {
        this.table.search = search
        this.getDataAxios(search)
      },
      deep: true,

      // immediate: true,
    },
    tableOptions: {
      handler() {
        this.getDataAxios()
      },
      deep: true,
    },
    start_date: {
      handler() {
        this.getDataAxios()
      },
      deep: true,
    },
    end_date: {
      handler() {
        this.getDataAxios()
      },
      deep: true,
    },
  },

  created() {
    this.getAllSections()
  },
  methods: {
    async getDataAxios() {
      let { search } = this.table
      this.table.loading = true
      let { page, itemsPerPage } = this.tableOptions
      if (!search) {
        search = null
      }

      if (!page) {
        page = 1
      }
      if (!itemsPerPage) {
        itemsPerPage = 10
      }

      const response = await employeeApi.get({
        search,
        page,
        limit: itemsPerPage,
        start_date: this.start_date,
        end_date: this.end_date,
      })

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.table.loading = false
        this.showDialogfunction(response.data.results, '#FF5252')
      } else {
        this.table.loading = false
        this.table.Data = response.data.results.data
        this.table.totalData = response.data.results.count
      }
    },

    editItem(item) {
      this.editedItem = { ...item }
      this.dialogEdit.open = true
    },

    async editItemConform() {
      this.dialogEdit.open = true
      this.dialogEdit.loading = true

      const response = await employeeApi.edit({
        employee_name: this.editedItem.employee_name,
        employee_mobile: this.editedItem.employee_mobile,
        employee_address: this.editedItem.employee_address,
        employee_salary: this.editedItem.employee_salary,
        employee_section: this.editedItem.employee_section,
        employee_start_date: this.editedItem.employee_start_date,
        employee_note: this.editedItem.employee_note,
        employee_id: this.editedItem._id,
      })

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.dialogEdit.open = false
        this.dialogEdit.loading = false
        this.showDialogfunction(response.data.results, '#FF5252')
      } else {
        this.dialogEdit.open = false
        this.dialogEdit.loading = false
        this.getDataAxios()
        this.showDialogfunction(response.data.results, 'primary')
      }
    },

    search() {
      this.$router.replace(
        {
          query: { search: this.table.search },
        },
        () => {},
      )
    },

    async add() {
      this.addDialog.loading = true

      const response = await employeeApi.add({
        employee_name: this.addData.employee_name,
        employee_mobile: this.addData.employee_mobile,
        employee_address: this.addData.employee_address,
        employee_salary: this.addData.employee_salary,
        employee_section: this.addData.employee_section,
        employee_start_date: this.addData.employee_start_date,
        employee_note: this.addData.employee_note,
      })

      if (response.status === 401) {
        this.addDialog.loading = false
        this.addDialog.open = false
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.addDialog.loading = false
        this.addDialog.open = false
        this.showDialogfunction(response.data.results, '#FF5252')
      } else {
        this.addDialog.loading = false
        this.addDialog.open = false
        this.showDialogfunction(response.data.results, 'primary')
        this.getDataAxios()
      }
    },

    deleteItem(item) {
      this.deletedItem = { ...item }
      this.dialogDelete = true
    },

    async deleteItemConfirm() {
      this.deleteItemLoading = true

      const response = await employeeApi.remove(this.deletedItem._id)

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.deleteItemLoading = false
        this.showDialogfunction(response.data.results, '#FF5252')
      } else {
        this.deleteItemLoading = false
        this.dialogDelete = false
        this.getDataAxios()
        this.showDialogfunction(response.data.results, 'primary')
      }
    },

    async getAllSections() {
      this.sections.loading = true

      const response = await sectionApi.getAll()

      if (response.status === 401) {
        this.sections.loading = false
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.sections.loading = false
        this.showDialogfunction(response.data.results, '#FF5252')
      } else {
        this.sections.loading = false
        this.sections.data = response.data.results
      }
    },

    showDialogfunction(bodyText, color) {
      this.dialogData.open = true
      this.dialogData.bodyText = bodyText
      this.dialogData.color = color
    },

    isNumber(evt) {
      const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.']
      const keyPressed = evt.key
      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault()
      }
    },

    numberWithComma,
  },
}
</script>
